export const LOGIN_TYPES = {
  SAML: "saml",
  PASSWORD: "password",
  DEFAULT: ""
} as const

export const VIEWSTATES_LIST = {
  SSO: "sso",
  PASSWORD: "password",
  LOGIN: "login",
  PASSWORD_RECOVERY: "passwordRecovery",
  EMAIL_SSO: "emailSso"
} as const
