import { createApp } from "vue"

import { init, getIncludedJsonData } from "@/src/core/init"
import { getSentryParams, initSentry } from "@/src/core/sentry"
import tooltip from "@/src/directives/tooltip"
import LoginPage from "@/src/domains/auth/login-page/login-page.vue"
import { getUserInfo, loadAnonymousUser } from "@/src/domains/user/user"
import { initPlugins } from "@/src/plugins"
import { initIntl } from "@/src/plugins/intl-setup"
import LoginPageOcea from "@/src/specifics/ocea_sb/login-page.vue"
import { setLocale } from "@/src/utils/time-helper"

import { sendPageView } from "./src/plugins/analytics"
import { $events } from "./src/plugins/vue_event_hub"

const initLoginApp = async () => {
  const includedJsonData = getIncludedJsonData()
  await initIntl()

  const user = await loadAnonymousUser(includedJsonData)

  globalThis.user_config = user
  Object.freeze(globalThis.user_config)

  init(user.global)
  setLocale()

  mountLoginApp()
}

const mountLoginApp = async () => {
  const mainComponent =
    globalThis.user_config.global?.design === "ocea_sb" ? LoginPageOcea : LoginPage
  const config = {
    user: globalThis.user_config
  }

  const mainProps = globalThis.main_vue_props || {}
  const { email, loginType, username } = getUserInfo()

  const app = createApp(mainComponent, {
    ...mainProps,
    email,
    loginType,
    samlEmail: config.user.samlEmail,
    samlProviders: config.user.global.samlProviders || [],
    environment: config.user.global.env_level,
    customLogo: globalThis.user_config.global.customLogo,
    username
  })

  initSentry({ app, ...getSentryParams() })
  initPlugins(app)

  app.config.globalProperties.$events = $events
  app.directive("dr-tooltip", tooltip)
  sendPageView()
  app.mount("#login")
}

initLoginApp()
