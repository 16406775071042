<template>
  <LoginWrapper>
    <div class="login-card">
      <DrChip v-if="displayChip" :class="`chip chip--${environment}`">{{ environmentName }}</DrChip>
      <div class="login-form">
        <LoginCardHeader
          :show-back-button="
            viewState === VIEWSTATES_LIST.PASSWORD_RECOVERY ||
            viewState === VIEWSTATES_LIST.EMAIL_SSO
          "
          :custom-logo="props.customLogo"
          @back-to-login="backToLogin">
          {{ title }}
        </LoginCardHeader>
        <div class="login-formWrapper">
          <section v-if="viewState !== VIEWSTATES_LIST.SSO" class="login-fields">
            <div class="login-row">
              <DrLabel
                :label="translate({ defaultMessage: 'Email', description: { tags: 'login' } })">
                <DrInput
                  name="email"
                  :value="emailModel"
                  :error="emailError"
                  autofocus
                  @input="(evt) => (emailModel = evt)" />
              </DrLabel>
            </div>
            <transition name="slide">
              <div
                v-if="
                  viewState !== VIEWSTATES_LIST.PASSWORD_RECOVERY &&
                  viewState !== VIEWSTATES_LIST.EMAIL_SSO &&
                  viewState !== VIEWSTATES_LIST.LOGIN
                "
                class="login-row">
                <DrLabel
                  :label="
                    translate({ defaultMessage: 'Password', description: { tags: 'login' } })
                  ">
                  <DrInput type="password" :value="password" @input="(evt) => (password = evt)" />
                </DrLabel>
              </div>
            </transition>
          </section>
        </div>

        <div class="login-submit">
          <DrButton
            type="primary"
            class="u-full-width"
            :disabled="!emailModel.length"
            @click="submit">
            {{ connectionText }}
          </DrButton>
        </div>

        <transition name="slide">
          <div
            v-if="
              viewState === VIEWSTATES_LIST.SSO ||
              viewState === VIEWSTATES_LIST.LOGIN ||
              viewState === VIEWSTATES_LIST.PASSWORD
            "
            class="login-reset-password">
            <a
              v-if="viewState === VIEWSTATES_LIST.SSO"
              class="login-link"
              @click="clickImNotUsername"
              v-html="
                translate({
                  defaultMessage: 'Log in with another account',
                  description: { tags: 'login' }
                })
              ">
            </a>

            <a
              v-else-if="
                viewState === VIEWSTATES_LIST.LOGIN || viewState === VIEWSTATES_LIST.PASSWORD
              "
              class="login-link"
              @click="forgotPassword"
              >{{
                translate({
                  defaultMessage: "Forgotten password ?",
                  description: { tags: "login" }
                })
              }}</a
            >
          </div>
        </transition>
      </div>
    </div>
    <LoginBanner />
  </LoginWrapper>
</template>

<script setup lang="ts">
import { AxiosError } from "axios"
import debounce from "lodash/debounce"
import { computed, onMounted, ref, watch, onUnmounted } from "vue"

import { useTranslate } from "@/src/composables/use-translate"
import { $httpErrorHandler } from "@/src/plugins/vue_axios"
import { $toast, $toastError } from "@/src/plugins/vue_toasts"
import DrButton from "@/src/ui/button/button.vue"
import DrChip from "@/src/ui/chip.vue"
import DrLabel from "@/src/ui/form/dr-label.vue"
import DrInput from "@/src/ui/inputs/dr-input.vue"
import { transformLocationHash } from "@/src/utils/urls"

import LoginBanner from "../components/login-banner.vue"
import LoginCardHeader from "../components/login-card-header.vue"
import LoginWrapper from "../components/login-wrapper.vue"
import { postLogin, postRecover } from "../services"

import { LOGIN_TYPES, VIEWSTATES_LIST } from "./login-page.consts"

import type { LoginTypes, ViewStatesList } from "./login-page.types"

type Props = {
  email: string
  loginType: LoginTypes
  error: string
  samlProviders: { id: string; text: string }[]
  customLogo: string
  environment: string
  username: string
}

const props = withDefaults(defineProps<Props>(), {
  email: "",
  loginType: LOGIN_TYPES.DEFAULT,
  error: "",
  samlProviders: () => [],
  customLogo: "",
  environment: "",
  username: ""
})

const { translate } = useTranslate()

const emailModel = ref(props.email)
const password = ref("")
const viewState = ref<ViewStatesList>(VIEWSTATES_LIST.LOGIN)

const emailIsValid = ref(false)
const emailError = ref("")

onMounted(() => {
  if (props.loginType === LOGIN_TYPES.SAML) {
    viewState.value = VIEWSTATES_LIST.SSO
  } else if (props.loginType === LOGIN_TYPES.PASSWORD) {
    viewState.value = VIEWSTATES_LIST.PASSWORD
  } else {
    viewState.value = VIEWSTATES_LIST.LOGIN
  }

  const search = transformLocationHash(document.location)
  if (search) {
    history.pushState("", "", document.location.pathname + search)
  }

  globalThis.addEventListener("keyup", submitOnEnter)
})

onUnmounted(() => globalThis.removeEventListener("keyup", submitOnEnter))

watch(
  () => props.email,
  () => {
    if (emailModel.value && viewState.value !== VIEWSTATES_LIST.SSO && emailError.value) {
      emailValidation()
    }
  }
)

const submitOnEnter = ({ type, key }: KeyboardEvent) => {
  if (type === "keyup" && key === "Enter") {
    submit()
  }
}

const title = computed(() => {
  return props.username && viewState.value === VIEWSTATES_LIST.SSO
    ? `${translate({ defaultMessage: "Hello", description: { tags: "login" } })} ${props.username}`
    : "Deepki Ready"
})

const ssoText = computed(() => {
  if (props.samlProviders.length === 1) {
    return translate(
      { defaultMessage: "Connect with {saml}", description: { tags: "login" } },
      { saml: props.samlProviders[0].text }
    )
  }
  return translate({ defaultMessage: "Connect with SSO", description: { tags: "login" } })
})

const connectionText = computed(() => {
  if (viewState.value === VIEWSTATES_LIST.SSO) {
    return ssoText.value
  } else if (viewState.value === VIEWSTATES_LIST.PASSWORD_RECOVERY) {
    return translate({ defaultMessage: "Reset my password", description: { tags: "login" } })
  }
  return translate({ defaultMessage: "Connect", description: { tags: "login" } })
})

const displayChip = computed(() => {
  return props.environment && props.environment !== "prod"
})

const environmentName = computed(() => {
  return props.environment === "dev" ? "local" : props.environment
})

const emailValidation = () => {
  if (
    emailModel.value.match(
      /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[a-zA-Z0-9]+)$/
    )?.length
  ) {
    emailIsValid.value = true
    emailError.value = ""
  } else {
    emailIsValid.value = false
    emailError.value = translate({
      defaultMessage: "Hmm, this doesn’t look like a valid email.",
      description: { tags: "login" }
    })
  }
}

const forgotPassword = () => {
  viewState.value = VIEWSTATES_LIST.PASSWORD_RECOVERY
}

const backToLogin = () => {
  viewState.value = VIEWSTATES_LIST.LOGIN
}

const clickImNotUsername = () => {
  emailModel.value = ""
  backToLogin()
}

const submit = debounce(
  () => {
    if (viewState.value === VIEWSTATES_LIST.PASSWORD_RECOVERY) {
      recover()
    } else if (
      [
        VIEWSTATES_LIST.LOGIN,
        VIEWSTATES_LIST.SSO,
        VIEWSTATES_LIST.EMAIL_SSO,
        VIEWSTATES_LIST.PASSWORD
      ].includes(viewState.value)
    ) {
      login()
    }
  },
  400,
  {
    leading: true,
    trailing: false
  }
)

// TODO: Split the 2 login behaviours
// First: A post to get the login_type
// Second: A post to login
const login = async () => {
  emailValidation()
  if (!emailIsValid.value) return

  const post = { email: emailModel.value } as any

  if (viewState.value !== VIEWSTATES_LIST.LOGIN && viewState.value !== VIEWSTATES_LIST.SSO) {
    post.password = password.value
  }

  try {
    const responseData = await postLogin({
      urlParams: document.location.search,
      post
    })

    if ("login_type" in responseData && responseData.login_type === LOGIN_TYPES.PASSWORD) {
      viewState.value = VIEWSTATES_LIST.PASSWORD
    } else if ("login_type" in responseData && responseData.login_type === LOGIN_TYPES.SAML) {
      viewState.value = VIEWSTATES_LIST.SSO
    } else if ("redirect" in responseData && responseData.redirect) {
      document.location = responseData.redirect
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      const payload = error?.response?.data?.error
      $toastError(
        payload?.message ||
          payload ||
          translate({
            defaultMessage: "An error has occurred. Please retry later.",
            description: { tags: "login" }
          }),
        "",
        "fa-exclamation-triangle"
      )
    } else {
      $httpErrorHandler(error)
    }
  }
}

const recover = async () => {
  emailValidation()
  if (!emailIsValid.value) return

  try {
    await postRecover(emailModel.value)

    $toast(
      translate({ defaultMessage: "Check your mailbox !", description: { tags: "login" } }),
      "",
      "fa-paper-plane"
    )
    viewState.value = VIEWSTATES_LIST.LOGIN
  } catch (err) {
    const error = err as { message?: string }
    $toastError(
      error.message ||
        error ||
        translate({
          defaultMessage: "An error has occurred. Please retry later.",
          description: { tags: "login" }
        }),
      "",
      "fa-exclamation-triangle"
    )
  }
}
</script>

<style lang="scss">
@import "@/src/style/main.scss";

.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-formWrapper {
    display: flex;
  }

  &-card {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: auto;
    border-radius: $dr-border-radius-small;
    background-color: $dr-light;
  }

  &-form {
    padding: 0 40px 40px;
    box-shadow: $dr-box-shadow;
  }

  &-fields {
    width: 100%;
  }

  &-row {
    margin-bottom: $dr-margin-big;
    height: 55px;
  }

  &-submit {
    margin-top: $dr-margin;
  }

  &-sso {
    height: 32px;
    margin-top: $dr-margin-big;
  }

  &-reset-password {
    margin-top: 15px;
    height: 15px;
  }

  &-link {
    display: block;
    font-size: $dr-font-size-caption;
    text-align: center;
    color: $dr-color-primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.chip {
  position: absolute;
  right: $dr-margin;
  top: $dr-margin;
  border-radius: 50px !important;
  padding: math.div($dr-margin, 2) $dr-margin-big;

  &--uat {
    background-color: $dr-disclaimer;
    color: $dr-light;
  }

  &--testing {
    background-color: $dr-gauze-grey;
  }

  &--dev {
    background-color: $dr-black;
    color: $dr-light;
  }
}

.u-full-width {
  width: 100%;
}

/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-enter-active {
  transition: all 0.2s ease-in;
}
.slide-leave-active {
  transition: all 0.2s ease-in;
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateY(60px);
  opacity: 0;
  height: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.login-logo {
  max-width: 100%;
}
</style>
