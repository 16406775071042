<template>
  <main
    class="login login--background login--ocea_sb"
    style="background-image: url(/static/img/ocea_sb/fond_isi-pro-expert.jpg)"
    @keyup.enter="submit()">
    <div class="login-card">
      <div class="login-form">
        <header class="login-header">
          <div
            v-if="viewState === 'passwordRecovery' || viewState === 'emailSso'"
            class="login-back"
            @click="backToLogin">
            <DrIcon icon="fa-chevron-left" />
          </div>
          <div class="login-title">
            <div class="login-useless-barre" />
            <h1 style="color: white">Bienvenue</h1>
            <h2 style="color: white">sur votre portail</h2>
            <img class="logo" src="/static/img/ocea_sb/logo_isi_pro_expert.svg" />

            <img
              class="logo-mobile"
              src="/static/img/ocea_sb/osb_blanc.png"
              alt="OCEA Smart Building" />
            <img
              src="/static/img/ocea_sb/logo_osb.png"
              alt="OCEA Smart Building"
              class="logo_desktop"
              width="280" />
          </div>
        </header>
        <div class="login-formWrapper">
          <DrIcon icon="far fa-user-unlock" class="login-formWrapper-icon" />
          <section class="login-fields">
            <div class="login-row">
              <DrLabel
                :label="translate({ defaultMessage: 'Email', description: { tags: 'login' } })">
                <DrInput
                  name="email"
                  :value="emailModel"
                  placeholder="identifiant"
                  @input="emailModel = $event" />
              </DrLabel>
            </div>
            <transition name="slide">
              <div
                v-if="viewState !== 'passwordRecovery' && viewState !== 'emailSso'"
                class="login-row">
                <DrLabel
                  :label="
                    translate({ defaultMessage: 'Password', description: { tags: 'login' } })
                  ">
                  <DrInput
                    type="password"
                    :value="password"
                    placeholder="Mot de passe"
                    @input="password = $event" />
                </DrLabel>
              </div>
            </transition>
          </section>
        </div>

        <div class="login-submit">
          <DrButton
            type="primary"
            class="u-full-width"
            :disabled="!emailModel.length"
            @click="submit">
            {{ translate({ defaultMessage: "Connect", description: { tags: "login" } }) }}
          </DrButton>
        </div>

        <transition name="slide">
          <div v-if="viewState === 'sso' || viewState === 'login'" class="login-reset-password">
            <a class="login-link" @click="forgotPassword">{{
              translate({ defaultMessage: "Forgotten password ?", description: { tags: "login" } })
            }}</a>
          </div>
        </transition>
      </div>
    </div>
    <div class="login-banner">
      <img
        src="https://static.aws.deepki.com/dr2/logo/new_deepki_logo_light.svg"
        alt="Deepki Logo" />
      <a @click="submitMultiProviders">{{
        translate({
          defaultMessage: "Connect with SSO",
          description: { tags: "login" }
        })
      }}</a>
    </div>
    <ToastManager />
  </main>
</template>

<script setup lang="ts">
import { AxiosError } from "axios"
import { onMounted, ref, watch } from "vue"

import { useTranslate } from "@/src/composables/use-translate"
import { LOGIN_TYPES, VIEWSTATES_LIST } from "@/src/domains/auth/login-page/login-page.consts"
import { postLogin, postRecover } from "@/src/domains/auth/services"
import { $httpErrorHandler } from "@/src/plugins/vue_axios"
import { $toast, $toastError } from "@/src/plugins/vue_toasts"
import DrButton from "@/src/ui/button/button.vue"
import DrIcon from "@/src/ui/dr-icon.vue"
import DrLabel from "@/src/ui/form/dr-label.vue"
import DrInput from "@/src/ui/inputs/dr-input.vue"
import ToastManager from "@/src/ui/toast/toast-manager.vue"
import { transformLocationHash } from "@/src/utils/urls"

import type { LoginTypes, ViewStatesList } from "@/src/domains/auth/login-page/login-page.types"

type Props = {
  email: string
  loginType: LoginTypes
  error: string
  samlProviders: { id: string; text: string }[]
  design: string
  environment: string
  username: string
}

const props = withDefaults(defineProps<Props>(), {
  email: "",
  loginType: LOGIN_TYPES.DEFAULT,
  error: "",
  samlProviders: () => [],
  design: "",
  environment: "",
  username: ""
})

const { translate } = useTranslate()

const emailModel = ref(props.email)
const password = ref("")
const viewState = ref<ViewStatesList>(VIEWSTATES_LIST.LOGIN)

const emailIsValid = ref(false)
const emailError = ref("")

onMounted(() => {
  if (props.loginType === LOGIN_TYPES.SAML) {
    viewState.value = VIEWSTATES_LIST.SSO
  } else if (props.loginType === LOGIN_TYPES.PASSWORD) {
    viewState.value = VIEWSTATES_LIST.PASSWORD
  } else {
    viewState.value = VIEWSTATES_LIST.LOGIN
  }

  const search = transformLocationHash(document.location)
  if (search) {
    history.pushState("", "", document.location.pathname + search)
  }
})

watch(
  () => props.email,
  () => {
    if (emailModel.value && viewState.value !== VIEWSTATES_LIST.SSO && emailError.value) {
      emailValidation()
    }
  }
)

const emailValidation = () => {
  if (
    emailModel.value.match(
      /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[a-zA-Z0-9]+)$/
    )?.length
  ) {
    emailIsValid.value = true
    emailError.value = ""
  } else {
    emailIsValid.value = false
    emailError.value = translate({
      defaultMessage: "Hmm, this doesn’t look like a valid email.",
      description: { tags: "login" }
    })
  }
}

const forgotPassword = () => {
  viewState.value = VIEWSTATES_LIST.PASSWORD_RECOVERY
}

const backToLogin = () => {
  viewState.value = VIEWSTATES_LIST.LOGIN
}

const submit = () => {
  if (viewState.value === VIEWSTATES_LIST.PASSWORD_RECOVERY) {
    recover()
  } else if (
    [
      VIEWSTATES_LIST.LOGIN,
      VIEWSTATES_LIST.SSO,
      VIEWSTATES_LIST.EMAIL_SSO,
      VIEWSTATES_LIST.PASSWORD
    ].includes(viewState.value)
  ) {
    login()
  }
}

// TODO: Split the 2 login behaviours
// First: A post to get the login_type
// Second: A post to login
const login = async () => {
  emailValidation()
  if (!emailIsValid.value) return

  const post: Parameters<typeof postLogin>[0]["post"] = { email: emailModel.value }

  if (viewState.value !== VIEWSTATES_LIST.LOGIN && viewState.value !== VIEWSTATES_LIST.SSO) {
    post.password = password.value
  }

  try {
    const responseData = await postLogin({
      urlParams: document.location.search,
      post
    })

    if ("login_type" in responseData && responseData.login_type === LOGIN_TYPES.PASSWORD) {
      viewState.value = VIEWSTATES_LIST.PASSWORD
    } else if ("login_type" in responseData && responseData.login_type === LOGIN_TYPES.SAML) {
      viewState.value = VIEWSTATES_LIST.SSO
    } else if ("redirect" in responseData && responseData.redirect) {
      document.location = responseData.redirect
    }
  } catch (err) {
    let error = err as { message?: string }
    if (err instanceof AxiosError) {
      error = err?.response?.data?.error
      $toastError(
        error?.message ||
          error ||
          translate({
            defaultMessage: "An error has occurred. Please retry later.",
            description: { tags: "login" }
          }),
        "",
        "fa-exclamation-triangle"
      )
    } else {
      $httpErrorHandler(error)
    }
  }
}

const recover = async () => {
  emailValidation()
  if (!emailIsValid.value) return

  try {
    await postRecover(emailModel.value)

    $toast(
      translate({ defaultMessage: "Check your mailbox !", description: { tags: "login" } }),
      "",
      "fa-paper-plane"
    )
    viewState.value = VIEWSTATES_LIST.LOGIN
  } catch (err) {
    const error = err as { message?: string }
    $toastError(
      error.message ||
        error ||
        translate({
          defaultMessage: "An error has occurred. Please retry later.",
          description: { tags: "login" }
        }),
      "",
      "fa-exclamation-triangle"
    )
  }
}

const submitMultiProviders = () => {
  if (emailModel.value) {
    login()
  } else {
    viewState.value = "emailSso"
  }
}
</script>

<style lang="scss">
@import "@/src/style/main.scss";

.login--ocea_sb {
  .login {
    &-card {
      margin: 0;
      height: 100%;
      overflow: visible;
      background-color: #0a71b3;
      max-width: 800px;

      @media (max-width: 1250px) {
        max-width: 100%;
      }
    }

    &-form {
      background: transparent;
      box-shadow: none;
      padding: 0 70px 40px;
      border-radius: 0;
    }

    &-formWrapper {
      width: 555px;
    }

    &-formWrapper-icon {
      font-size: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    &-useless-barre {
      position: absolute;
      width: 30px;
      height: 160px;
      margin-left: -70px;
      margin-top: 25px;
      background-color: white;
    }

    &-fields {
      width: 100%;
      margin-left: 30px;
    }

    &-back {
      top: calc(100% - 32px);
    }

    &-title {
      h1 {
        font-family: "Lato", "Roboto", "Helvetica", sans-serif;
        font-size: 56px;
        font-weight: 400;
        line-height: 1.35;
        letter-spacing: -0.02em;
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: left;
      }

      h1,
      h2,
      h3 {
        font-weight: 100;
      }

      .logo {
        margin-left: 5px;
        width: auto;
        height: auto;
        max-width: 450px;
        margin-bottom: 12px;
      }

      .logo-mobile {
        display: none;
        width: 150px;

        @media (max-width: 1250px) {
          display: block;
          position: absolute;
          z-index: 9999;
          right: -40px;
          top: 30px;
        }
      }

      .logo_desktop {
        position: fixed;
        right: 100px;
        top: 80px;

        @media (max-width: 1250px) {
          display: none;
        }
      }
    }

    &-banner {
      width: 100%;
      max-width: 800px;
      left: 0;
      padding: 40px;
      background-color: rgba(255, 255, 255, 0);

      a {
        color: white;
        font-size: $dr-font-size-caption;
        text-decoration: none;
      }

      img {
        height: 29px;
      }
    }

    &-link {
      display: block;
      font-size: 14px;
      text-align: center;
      color: white;
      cursor: pointer;
    }

    &-submit {
      .button-container {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: white;
        border: none;
        box-shadow: none;
        width: 525px;
        height: 50px;
        text-transform: uppercase;
        font-family: "Avenir";
        font-weight: 800;
        color: #0a71b3;
        font-size: 18px;
        border-radius: 25px;
        margin-top: 40px;
        margin-left: 30px;
        cursor: pointer;
      }

      button:hover {
        .button-container {
          background-color: white;
          opacity: 0.98;
        }
      }
    }

    &-reset-password {
      max-width: 555px;
    }
  }

  input {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
    color: white;
    font-size: 16px;

    &:focus {
      border-color: white !important;
      border-width: 1px !important;
      border-style: solid !important;
    }

    &::placeholder {
      color: white;
    }
  }

  .label {
    display: none;
  }
}

.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-formWrapper {
    display: flex;
  }

  &-card {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: auto;
    border-radius: $dr-border-radius-small;
    background-color: $dr-light;
  }

  &-form {
    padding: 0 40px 40px;
    box-shadow: $dr-box-shadow;
  }

  &-fields {
    width: 100%;
  }

  &-header {
    position: relative;
    padding: 40px 0;
  }

  &-back {
    position: absolute;
    top: calc(50% - 12px);
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
    background-color: $dr-charcoal-grey-lightest;
    border-radius: 50%;
    font-size: $dr-font-size-caption;
    cursor: pointer;

    &:hover {
      background-color: $dr-charcoal-grey-lighter;
    }
  }

  &-title {
    h1 {
      margin: 0;
      text-align: center;
      font-family: $dr-font-family-secondary;
      font-size: $dr-font-size-title;
      font-weight: bold;
    }
  }

  &-row {
    margin-bottom: $dr-margin-big;
    height: 55px;
  }

  &-submit {
    margin-top: $dr-margin;
  }

  &-sso {
    height: 32px;
    margin-top: $dr-margin-big;
  }

  &-reset-password {
    margin-top: 15px;
    height: 15px;
  }

  &-link {
    display: block;
    font-size: $dr-font-size-caption;
    text-align: center;
    color: $dr-color-primary;
    cursor: pointer;
  }

  &-banner {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 0 $dr-margin-bigger;
    background-color: rgba(255, 255, 255, 0.88);

    a {
      color: $dr-color-primary;
      font-size: $dr-font-size-caption;
      cursor: pointer;
    }

    img {
      height: 29px;
    }
  }

  &--background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.u-full-width {
  width: 100%;
}

/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-enter-active {
  transition: all 0.2s ease-in;
}
.slide-leave-active {
  transition: all 0.2s ease-in;
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateY(60px);
  opacity: 0;
  height: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
